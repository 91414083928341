import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';
import {
  EOrderStatus,
  EPickupInStoreStatus,
  EReturnStatus,
  EStatusColor,
  ETicketItemStatus,
  ETicketStatus,
} from '@enums';

export const CONSTANTS = {
  META_TAGS: {
    TITLE: {
      DEFAULT_TITLE: bitfToTranslate('COMMON.META_TAGS.DEFAULT_TITLE'),
      POSTFIX: bitfToTranslate('COMMON.META_TAGS.TITLE_POSTFIX'),
    },
    DESCRIPTION: bitfToTranslate('COMMON.META_TAGS.DESCRIPTION'),
  },
  SUPPORTED_LANGUAGES: [
    { code: 'en', label: 'English' },
    { code: 'it', label: 'Italiano' },
  ],
  DEFAULT_LANGUAGE: { code: 'it', label: 'Italiano' },
  SUPPORTED_CURRENCIES: [
    { code: 'EUR', label: 'Euro' },
    { code: 'GBP', label: 'Pound sterling' },
    { code: 'USD', label: 'Dollar' },
  ],
  DEFAULT_CURRENCY: { code: 'EUR', label: 'Euro' },
  LOCAL_STORAGE_VERSION: 1,
  COMPLETED_PAYMENT_STATUS: 'committed',
  STATUSES: {
    orderStatuses: {
      [EOrderStatus.NEW]: EStatusColor.GREY,
      [EOrderStatus.CLOSED]: EStatusColor.GREEN,
      [EOrderStatus.COMPLETED]: EStatusColor.GREEN,
      [EOrderStatus.IN_PROGRESS]: EStatusColor.YELLOW,
      [EOrderStatus.SHIPPED]: EStatusColor.GREEN,
      [EOrderStatus.COMMITTED]: EStatusColor.YELLOW,
      [EOrderStatus.PAYMENT_PENDING]: EStatusColor.YELLOW,
      [EOrderStatus.ERROR]: EStatusColor.RED,
      [EOrderStatus.OUT_OF_STOCK]: EStatusColor.RED,
    },
    returnStatuses: {
      [EReturnStatus.PENDING]: EStatusColor.GREY,
      [EReturnStatus.ACCEPTED]: EStatusColor.YELLOW,
      [EReturnStatus.DENY]: EStatusColor.RED,
      [EReturnStatus.CLOSED]: EStatusColor.GREEN,
      [EReturnStatus.RETURNED]: EStatusColor.GREEN,
      [EReturnStatus.RETURNEDINSTORE]: EStatusColor.YELLOW,
      [EReturnStatus.SENTTOLOGISTIC]: EStatusColor.GREEN,
    },
    returnStatusesRules: {
      [EReturnStatus.PENDING]: [EReturnStatus.ACCEPTED, EReturnStatus.DENY],
      [EReturnStatus.ACCEPTED]: [EReturnStatus.DENY, EReturnStatus.CLOSED, EReturnStatus.RETURNEDINSTORE],
      [EReturnStatus.DENY]: [EReturnStatus.ACCEPTED, EReturnStatus.CLOSED],
      [EReturnStatus.RETURNEDINSTORE]: [EReturnStatus.SENTTOLOGISTIC],
      [EReturnStatus.CLOSED]: [],
      [EReturnStatus.RETURNED]: [],
    },
    ticketStatuses: {
      [ETicketStatus.NEW]: EStatusColor.GREY,
      [ETicketStatus.IN_PROGRESS]: EStatusColor.YELLOW,
      [ETicketStatus.WAITING_FOR_DELIVERY]: EStatusColor.YELLOW,
      [ETicketStatus.READY_FOR_SHIPMENT]: EStatusColor.GREEN,
      [ETicketStatus.PACK_PREPARED]: EStatusColor.GREEN,
      [ETicketStatus.SHIPMENT_REQUESTED]: EStatusColor.YELLOW,
      [ETicketStatus.GOODS_RECEIVED]: EStatusColor.GREEN,
      [ETicketStatus.READY_FOR_THE_CUSTOMER]: EStatusColor.GREEN,
      [ETicketStatus.PACK_DELIVERED]: EStatusColor.GREEN,
      [ETicketStatus.SHIPPED]: EStatusColor.GREEN,
      [ETicketStatus.ERROR]: EStatusColor.RED,
      [ETicketStatus.CANCELED]: EStatusColor.RED,
    },
    ticketItemStatuses: {
      [ETicketItemStatus.NEW]: EStatusColor.GREY,
      [ETicketItemStatus.ERROR]: EStatusColor.RED,
      [ETicketItemStatus.CANCELED]: EStatusColor.RED,
      [ETicketItemStatus.PRODUCT_UNAVAILABLE]: EStatusColor.RED,
      [ETicketItemStatus.PRODUCT_AVAILABLE]: EStatusColor.GREEN,
      [ETicketItemStatus.RESERVED_GOODS]: EStatusColor.GREEN,
      [ETicketItemStatus.PACK_RECEIVED]: EStatusColor.GREEN,
      [ETicketItemStatus.SHIPMENT_REQUESTED]: EStatusColor.GREY,
      [ETicketItemStatus.PACK_SHIPPED_TO_CUSTOMER]: EStatusColor.GREEN,
      [ETicketItemStatus.PACK_SHIPPED]: EStatusColor.GREEN,
      [ETicketItemStatus.PACK_READY]: EStatusColor.GREEN,
      [ETicketItemStatus.DELIVERED_TO_THE_CUSTOMER]: EStatusColor.GREEN,
    },
    pickupInStoreStatuses: {
      [EPickupInStoreStatus.DELIVERED]: EStatusColor.GREEN,
      [EPickupInStoreStatus.RETURNED]: EStatusColor.GREY,
      [EPickupInStoreStatus.TO_BE_DELIVERED]: EStatusColor.YELLOW,
      [EPickupInStoreStatus.TO_BE_RECEIVED]: EStatusColor.GREY,
      [EPickupInStoreStatus.TO_BE_RETURNED]: EStatusColor.YELLOW,
    },
  },
  COLORS: {
    [EStatusColor.GREY]: {
      background: '#CCCED4',
      text: '#3B3F4F',
    },
    [EStatusColor.GREEN]: {
      background: '#D7F8D5',
      text: '#3F9144',
    },
    [EStatusColor.YELLOW]: {
      background: '#FFF2D5',
      text: '#A8874E',
    },
    [EStatusColor.RED]: {
      background: '#F9D0CF',
      text: '#B42D3E',
    },
    [EStatusColor.ORANGE]: {
      background: '#FFD8A9',
      text: '#955B23',
    },
  },
};
// FIXME: remove me
window['printStatuses'] = () => {
  console.log(CONSTANTS.STATUSES);
  return CONSTANTS.STATUSES;
};
