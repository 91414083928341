<soh-expandable-panel [title]="'COMMON.LABEL.ITEMS' | translate"
  *ngIf="orderItems"
  [isExpanded]="isExpanded">

  <soh-dynamic-table [dataSource]="orderItems"
    [tableClass]="'cells-vertical-align-top border-on-cell-bottom ' + tableClass"
    [fullWidth]="true"
    [excludedColumns]="excludedColumns"
    [allColumns]="[
    {
      property: 'image',
      title: 'COMMON.LABEL.PRODUCT_IMAGE' | translate,
      template: imageColumn,
      classHeader:'image-header',
      classRow:'pt-2'
    },
    {
      property: 'sku',
      title: 'COMMON.LABEL.PRODUCT_SKU' | translate,
      template: skuColumn
    },
    {
      property: 'productName',
      title: 'COMMON.LABEL.PRODUCT_NAME' | translate
    },
    {
      property: 'size',
      title: 'COMMON.LABEL.PRODUCT_SIZE' | translate,
      template:sizeColumn
    },
    {
      property: 'color',
      title: 'COMMON.LABEL.PRODUCT_COLOR' | translate,
      template: colorColumn
    },
    {
      property: 'amount',
      title: 'COMMON.LABEL.PRODUCT_QTY' | translate
    },
    ]">
  </soh-dynamic-table>

  <mat-toolbar *ngIf="totalPrice!==undefined"
    class="justify-content-end total-price-toolbar">
    <span class="total-string">{{'COMMON.LABEL.TOTAL' | translate}}</span>
    <span class="total-value ml-2">
      {{totalPrice | bitfCurrency:currency}}
    </span>
  </mat-toolbar>

</soh-expandable-panel>

<ng-template #imageColumn
  let-item="item">
  <ng-container *ngIf="item.product?.defaultImage?.fullpath; else withoutImage">
    <img class="product-image"
      [src]="imagesUrl + item.product?.defaultImage?.fullpath" />
  </ng-container>
  <ng-template #withoutImage>--</ng-template>
</ng-template>

<ng-template #skuColumn
  let-item="item">
  {{item.product?.sku || '--'}}
</ng-template>

<ng-template #sizeColumn
  let-item="item">
  {{item.product?.size ? item.product.size.code :'--'}}
</ng-template>

<ng-template #colorColumn
  let-item="item">
  {{item.product?.color ? item.product?.color.name :'--'}}
</ng-template>
