<soh-expandable-panel [title]="title"
  [class]="className"
  *ngIf="items">

  <soh-dynamic-table [dataSource]="items"
    [allColumns]="[
    {
      property: 'label',
      title: '',
      template:labelTemplate
    },
    {
      property: 'value',
      title: '',
      template:valueTemplate
    }
    ]"
    tableClass="without-header">
  </soh-dynamic-table>

</soh-expandable-panel>

<ng-template #labelTemplate
  let-item="item">
  <div class="cell-with-border blue-text font-medium">
    {{item.label | translate}}
  </div>
</ng-template>

<ng-template #valueTemplate
  let-item="item">
  <div class="cell-with-border">
    <ng-container *ngIf="item.specialLink;else pureValueTemplate">
      <a [href]="item.specialLink">
        <ng-container *ngIf="item.translateValue; else valueWithoutTranslation">
          {{item.value | translate}}
        </ng-container>
      </a>
    </ng-container>
    <ng-template #pureValueTemplate>
      <ng-container *ngIf="item.translateValue; else valueWithoutTranslation">
        {{item.value | translate}}
      </ng-container>
    </ng-template>
    <ng-template #valueWithoutTranslation>
      {{item.value}}
    </ng-template>
  </div>
</ng-template>