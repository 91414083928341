<span class="d-flex rounded-pill status-content"
  *ngIf="status"
  [ngClass]="{'cursor-pointer': isClickable}">

  <span class="px-2 rounded-pill mat-caption d-flex align-items-center"
    [ngStyle]="{'color': status.color.text ,'background-color': status.color.background}">
    {{status.label}}
    <mat-icon *ngIf="status.icon"
      class="m-0 p-0 ml-1 d-flex align-items-center justify-content-center">{{status.icon}}</mat-icon>
  </span>

  <mat-icon class="m-0 p-0 ml-1 d-flex align-items-center"
    *ngIf="isClickable">edit</mat-icon>
</span>