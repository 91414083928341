import { gql } from 'apollo-angular';

export const ORDER_LIST_FIELDS = gql`
  fragment OrderListFields on object_OnlineShopOrder {
    id
    ordernumber
    orderdate
    orderState
    origin
    pickingTipology
    customerFirstname
    customerLastname
    totalPrice
    currency
  }
`;

export const ORDER_FIELDS = gql`
  fragment OrderFields on object_OnlineShopOrder {
    id
    ordernumber
    orderdate
    order_customer_firstname
    order_customer_lastname
    orderState
    orderhistory {
      event
      eventdate
    }
    paymentInfo {
      ... on fieldcollection_PaymentInfo {
        message
        amount
        currency
        paymentState
        gateway
        kind
      }
    }
    priceModifications {
      ... on fieldcollection_OrderPriceModifications {
        name
        netAmount
        amount
      }
    }
    currency
    deliveryCompany
    deliveryStreet
    deliveryCity
    deliveryZip
    deliveryProvince
    deliveryCountry
    shipped
    pickingTipology
    pickingStore {
      ... on object_Warehouse {
        name
        # warehouse_type
      }
    }
    customerFirstname
    customerLastname
    customerEmail
    customerStreet
    customerCity
    customerZip
    customerCompany
    customerProvince
    customerProvinceCode
    customerCountry
    totalPrice
    origin
  }
`;
