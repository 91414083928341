import { Product } from './product.model';
import { SuperModel } from './super-model.model';

export class ReturnedItem extends SuperModel {
  productName: string;
  amount: number;
  product?: Product;
  returnQty: number;
  returnReason: string;

  constructor({
    element,
    metadata,
  }: {
    element: Partial<ReturnedItem>;
    metadata?: { name: string; value: any }[];
  }) {
    super(element);
    if (element && element.product) {
      this.product = new Product({ element: element.product });
    }

    if (metadata) {
      const reason = metadata.find(item => item.name === 'reason');
      this.returnReason = reason ? reason.value : undefined;
      const returnQty = metadata.find(item => item.name === 'qty');
      this.returnQty = returnQty ? Number(returnQty.value) : undefined;
    }
  }

  get serialised() {
    return {};
  }
}
