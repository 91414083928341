import {
  IBitfGraphQlResponse,
  IBitfGraphQlRequest,
  IBitfApiPagination,
  IBitfGraphQlResponseMapper,
} from '@interfaces';
import { modelsMap } from '@common/parsers/models-mapper/models-mapper.strategy';

export class BitfGraphQlResponseMapper implements IBitfGraphQlResponseMapper {
  constructor() {}

  mapQueryResponse<T>(
    requestParams: IBitfGraphQlRequest,
    responseEnveloped: IBitfGraphQlResponse<any>
  ): IBitfGraphQlResponse<T> {
    // CONTENT
    this.mapContent(requestParams, responseEnveloped, true);

    // SORTING
    this.mapQuerySorting(requestParams, responseEnveloped);

    // PAGINATION
    this.mapQueryPagination(requestParams, responseEnveloped);

    return responseEnveloped as IBitfGraphQlResponse<T>;
  }

  mapMutationResponse<T>(
    requestParams: IBitfGraphQlRequest,
    responseEnveloped: IBitfGraphQlResponse<any>
  ): IBitfGraphQlResponse<T> {
    // CONTENT
    this.mapContent(requestParams, responseEnveloped, false);

    return responseEnveloped as IBitfGraphQlResponse<T>;
  }

  private mapContent(
    requestParams: IBitfGraphQlRequest,
    responseEnveloped: IBitfGraphQlResponse<any>,
    isQuery: boolean
  ) {
    if (responseEnveloped.originalBody === undefined || responseEnveloped.originalBody === null) {
      return undefined;
    }

    const { modelMapper } = requestParams;
    if (modelMapper) {
      const response = this.extractResponseAndMetadata(responseEnveloped, modelMapper, isQuery);
      if (response) {
        // NOTE: we've that model in the response we can create the object
        const model = this.getModel(modelMapper);
        if (model) {
          // NOTE: single response entity
          if (this.isResponseArray(response)) {
            // NOTE: response with array of objects
            responseEnveloped.content = response.edges.map(edge => new model(edge.node));
          } else {
            // NOTE: Response with a single object
            responseEnveloped.content = new model(response);
          }
        }
      }
    } else {
      // NOTE: this is a delete mutation
    }
  }

  private mapQuerySorting(requestParams: IBitfGraphQlRequest, responseEnveloped: IBitfGraphQlResponse<any>) {
    const { modelMapper } = requestParams;
    const response = this.extractResponseAndMetadata(responseEnveloped, modelMapper, true);
    if (response) {
      responseEnveloped.sorting = requestParams.sorting;
    }
  }

  private mapQueryPagination(
    requestParams: IBitfGraphQlRequest,
    responseEnveloped: IBitfGraphQlResponse<any>
  ) {
    const { modelMapper } = requestParams;
    const response = this.extractResponseAndMetadata(responseEnveloped, modelMapper, true);
    if (response) {
      if (requestParams.size || requestParams.page) {
        responseEnveloped.pagination = this.calculatePagination(requestParams, response);
      }
    }
  }

  private calculatePagination(requestParams: IBitfGraphQlRequest, response: any): IBitfApiPagination {
    // NOTE: page starts from 1 to avoid mismatch between page and totalPages
    const size = requestParams.size;
    const page = requestParams.page;
    const totalItems = response.totalCount;
    const totalPages = Math.ceil(totalItems / size);
    const first = page === 1;
    const last = page === totalPages;
    let itemsInPage;
    if (last) {
      if (totalItems % size > 0) {
        itemsInPage = totalItems % size;
      } else {
        itemsInPage = size;
      }
    } else {
      if (size * page <= totalItems) {
        itemsInPage = size;
      } else {
        itemsInPage = 0;
      }
    }
    return {
      first,
      last,
      page,
      size,
      itemsInPage,
      totalItems,
      totalPages,
    };
  }

  private extractResponseAndMetadata(responseEnveloped: any, modelMapper: string, isQuery: boolean) {
    let response: any;
    const originalBody = responseEnveloped.originalBody[modelMapper];
    if (!originalBody) {
      // NOTE: not logged in UI because this is a dev error in model strategy settings
      throw new Error(`responseEnveloped[${modelMapper}] not found`);
    }
    if (isQuery) {
      response = originalBody;
    } else {
      response = originalBody?.output;
      responseEnveloped.success = originalBody?.success;
      responseEnveloped.message = originalBody?.message;
    }

    return response;
  }

  private getModel(modelMapper: string) {
    const model = modelsMap.get(modelMapper);
    if (!model) {
      console.error(`model for modelMapper: ${modelMapper} not found`);
    }
    return model;
  }

  private isResponseArray(response): boolean {
    return !!response.edges;
  }
}
