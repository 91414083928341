<div class="mb-1"
  *ngIf="address.company">{{address.company}}
</div>

<div class="mb-1"
  *ngIf="address.name">{{address.name}}
</div>

<div class="mb-1"
  *ngIf="address.street || address.city || address.zip">
  {{address.street}}, {{address.city}} {{address.zip}}
</div>

<div *ngIf="address.province || address.provinceCode || address.country">
  {{address.province}} {{address.provinceCode}} - {{address.country}}
</div>

<div class="address-link">
  <a color="primary"
    mat-button
    href="https://www.google.com/maps/dir/?api=1&destination={{address.street}},{{address.zip}}"
    target="_blank">
    {{'COMMON.LABEL.MAP'| translate}}
    <mat-icon>ios_share</mat-icon>
  </a>
</div>