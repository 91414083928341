import { BitfGraphQlRequestMapper } from '../graph-ql-parser/bitf-graph-ql-request.mapper';
import { EBitfGraphQlParsers } from '@enums';

export class BitfPimcoreRequestMapper extends BitfGraphQlRequestMapper {
  name = EBitfGraphQlParsers.BITF_PIMCORE_PARSER;

  constructor() {
    super();
  }
}
