import { Injectable, Injector } from '@angular/core';

// eslint-disable-next-line max-len
import { BitfWebUiMessagesListenerService } from '@bitf/services/ui-messages-listener/web/bitf-web-ui-messages-listener.service';

@Injectable({
  providedIn: 'root',
})
export class UiMessagesListenerService extends BitfWebUiMessagesListenerService {
  constructor(protected injector: Injector) {
    super(injector);
  }
}
