import { QueryOptions, MutationOptions } from '@apollo/client/core';

import { IBitfGraphQlRequest, IBitfGraphQlRequestMapper } from '@interfaces';
import { EBitfGraphQlParsers } from '@enums';

export class BitfGraphQlRequestMapper implements IBitfGraphQlRequestMapper {
  name = EBitfGraphQlParsers.BITF_GRAPH_QL_PARSER;

  constructor() {}

  mapQueryRequest(requestParams: IBitfGraphQlRequest): QueryOptions {
    const { id, ids, search, filter, query, variables, context, sorting } = requestParams;
    let { size, page } = requestParams;
    const queryOptions: QueryOptions = { query };
    const mappedVariables: any = {};

    // CONTEXT
    if (context) {
      Object.assign(queryOptions, {
        context,
      });
    }

    // ID
    if (id !== undefined) {
      mappedVariables.id = id;
    }
    if (ids) {
      mappedVariables.ids = ids;
    }

    const filterAccumulator: string[] = [];
    // SEARCH
    if (search?.length) {
      filterAccumulator.push(...search);
    }

    // FILTER
    if (filter?.length) {
      filterAccumulator.push(...filter);
    }
    if (filterAccumulator.length) {
      mappedVariables.filter = `{"$and": [${filterAccumulator}]}`;
    }

    // SORTING
    if (sorting) {
      Object.assign(mappedVariables, {
        sortBy: sorting.map(sort => sort.property).reverse(),
        sortOrder: sorting.map(sort => sort.direction).reverse(),
      });
    }

    // PAGINATION
    if (size || page) {
      if (!size) {
        requestParams.size = 10;
        size = 10;
      }
      if (!page) {
        requestParams.page = 1;
        page = 1;
      }
      Object.assign(mappedVariables, {
        first: size,
        after: size * (page - 1),
      });
    }

    // CUSTOM VARIABLES
    if (variables) {
      Object.assign(mappedVariables, variables);
    }
    queryOptions.variables = mappedVariables;

    // console.log(queryOptions);

    return queryOptions;
  }

  mapMutationRequest(requestParams: IBitfGraphQlRequest): MutationOptions {
    const { id, mutation, body, variables, optimisticResponse, optimisticUpdate$, context } = requestParams;
    const mutationOptions: MutationOptions = { mutation };
    const mappedVariables: any = {};

    // CONTEXT
    if (context) {
      Object.assign(mutationOptions, {
        context,
      });
    }

    // ID
    if (id !== undefined) {
      mappedVariables.id = id;
    }

    // BODY
    if (body) {
      // NOTE read the id from the body to avoid to pass in requestParams
      // a separate id field
      if (mappedVariables.id === undefined && body.id !== undefined) {
        mappedVariables.id = body.id;
      }
      if (body.serialised) {
        // TODO: we need a method to extract the right props for the
        // current mutation
        const serialisedBody = body.serialised;
        delete serialisedBody.id;
        Object.assign(mappedVariables, { input: serialisedBody });
      } else {
        delete body.id;
        Object.assign(mappedVariables, { input: body });
      }
    }

    // CUSTOM VARIABLES
    if (variables) {
      Object.assign(mappedVariables, variables);
    }
    mutationOptions.variables = mappedVariables;

    if (optimisticResponse) {
      mutationOptions.optimisticResponse = optimisticResponse;
      mutationOptions.update = (proxy, data) => {
        // NOTE: this is a way to extract the optmistic update
        // below there is the method to update the InMemoryCache
        optimisticUpdate$.next(data);
        // Read the data from our cache for this query.
        // const data = proxy.readQuery({ query: CommentAppQuery });
        // Add our comment from the mutation to the end.
        // data.comments.push(submitComment);
        // Write our data back to the cache.
        // proxy.writeQuery({ query: CommentAppQuery, data });
      };
    }

    return mutationOptions;
  }
}
