import { gql } from 'apollo-angular';

export const RETURN_LIST_FIELDS = gql`
  fragment ReturnListFields on object_Returns {
    id
    firstName
    lastName
    refundType
    requestTime
    status
    ordernumber
    creationDate
    origin
  }
`;

export const RETURN_FIELDS = gql`
  fragment ReturnFields on object_Returns {
    id
    firstName
    lastName
    email
    phone
    address
    city
    zipCode
    province
    country
    refundType
    requestTime
    status
    closureDate
    ordernumber
    origin
    receivedInStoreAt
    shippedToLogisticAt
    deliveredInLogisticAt
    keptInStoreAt
    refundRequests {
      ... on object_RefundRequest {
        totalAmount
      }
    }
  }
`;
