import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BitfApiService } from '@bitf/services/api/bitf-api.service';
import { IBitfApiResponse } from '@interfaces';
import { User } from '@models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends BitfApiService {
  constructor(public injector: Injector) {
    super('user', injector);
  }

  getMe(): Observable<IBitfApiResponse<User>> {
    return super
      .request<User>({
        path: '/data',
        method: 'GET',
        disableHideLoader: true,
      })
      .pipe(
        // NOTE: this is a patch because the api is not respecting the envelope
        map(response => {
          response.content = response.originalBody;
          return response;
        })
      );
  }
}
