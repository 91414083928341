import { bitfToTranslate } from '@common/libs/bitforce/utils/bitf-translate.utils';

export const NAVIGATION_MENU = [
  {
    title: bitfToTranslate('COMMON.LABEL.DASHBOARD'),
    icon: '',
    routerLink: ['/dashboard'],
    svgIcon: 'dashboard-icon',
    iconSize: '32px',
  },
  // {
  //   title: bitfToTranslate('COMMON.LABEL.ORDERS'),
  //   icon: 'description',
  //   routerLink: ['/orders'],
  // },
  {
    title: bitfToTranslate('COMMON.LABEL.PICKUP_IN_STORE'),
    icon: '',
    routerLink: ['/pickup-in-store'],
    svgIcon: 'click-and-collect-icon',
    iconSize: '40px',
  },
  {
    title: bitfToTranslate('COMMON.LABEL.RETURNS'),
    icon: '',
    routerLink: ['/returns'],
    svgIcon: 'return-icon',
    iconSize: '40px',
  },
];
