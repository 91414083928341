import { Component, Input, OnInit } from '@angular/core';

import { IStatusColor } from '@interfaces';
import { CONSTANTS } from '@constants';
import { EStatusColor } from '@common/enums';

@Component({
  selector: 'soh-payment-status-badge',
  templateUrl: './payment-status-badge.component.html',
  styleUrls: ['./payment-status-badge.component.scss'],
})
export class PaymentStatusBadgeComponent implements OnInit {
  @Input() status: string;

  statusColor: IStatusColor;

  constructor() {}

  ngOnInit(): void {
    this.statusColor =
      this.status === CONSTANTS.COMPLETED_PAYMENT_STATUS
        ? CONSTANTS.COLORS[EStatusColor.GREEN]
        : CONSTANTS.COLORS[EStatusColor.ORANGE];
  }
}
