import { gql } from 'apollo-angular';
import { ORDER_FIELDS } from './order.gql';
import { PRODUCT_FIELDS } from './product.gql';
import { environment } from '@env/environment';

const {
  apiProperties: { size, objectProductSize },
} = environment;

export const PICKUP_IN_STORE_LIST_FIELDS = gql`
  fragment CollectOrdersInStoreFields on object_CollectOrdersInStore {
    id
    orderNumber
    status
    pickedAt
    creationDate
    order {
      ... on object_OnlineShopOrder {
        id
        customerFirstname
        customerLastname
        order_customer_firstname
        order_customer_lastname
        orderState
        ordernumber
        paymentInfo {
          ... on fieldcollection_PaymentInfo {
            paymentState
          }
        }
      }
    }
  }
`;

export const PICKUP_IN_STORE_NESTED_ORDER = gql`
  fragment OrderFields on object_OnlineShopOrder {
    id
    ordernumber
    orderdate
    order_customer_firstname
    order_customer_lastname
    orderState
    orderhistory {
      event
      eventdate
    }
    paymentInfo {
      ... on fieldcollection_PaymentInfo {
        message
        amount
        currency
        paymentState
        gateway
        kind
      }
    }
    currency
    deliveryCompany
    deliveryStreet
    deliveryCity
    deliveryZip
    deliveryProvince
    deliveryCountry
    shipped
    pickingTipology
    pickingStore {
      ... on object_Warehouse {
        name
        # warehouse_type
      }
    }
    customerFirstname
    customerLastname
    customerFullName
    customerEmail
    customerStreet
    customerCity
    customerZip
    customerCompany
    customerProvince
    customerProvinceCode
    customerCountry
    customerPhone
    totalPrice
    origin
    items {
      ... on object_OnlineShopOrderItem {
        id
        amount
        productName
        totalPrice
        product {
          ... on object_Product {
            sku
            images {
              ... on fieldcollection_ImageInfo {
                image {
                  filename
                  fullpath
                }
              }
            }
            color {
              ... on object_Color {
                name
              }
            }
            ${size} {
              ... on ${objectProductSize} {
                code
              }
            }
            parent {
              ... on object_Product {
                sku
                images {
                  ... on fieldcollection_ImageInfo {
                    image {
                      filename
                      fullpath
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const PICKUP_IN_STORE_DETAILS_FIELDS = gql`
  ${PICKUP_IN_STORE_NESTED_ORDER}
  fragment CollectOrdersInStoreFields on object_CollectOrdersInStore {
    id
    orderNumber
    clickAndCollectStore
    status
    receivedInStoreAt
    pickedAt
    movedToBeReturnedAt
    returnedBackToWarehouseAt
    verificationCustomerCode
    creationDate
    receivedInStoreAt
    order {
      ...OrderFields
    }
  }
`;
