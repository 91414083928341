import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';
import { BitfFormItem } from '@bitf/core/models';
import { EBitfApiRequestPartsNames, EApiRequestPartKeys, EBitfGraphQlQueryOperator } from '@enums';
import { IBitfPartData } from '@interfaces';

export class BitGraphQlFormItemConfigApiRequestPart extends BitfApiRequestPart {
  constructor({
    key,
    partName,
    defaultPartData,
  }: {
    key?: EApiRequestPartKeys;
    partName?: EBitfApiRequestPartsNames;
    defaultPartData?: IBitfPartData;
  } = {}) {
    if (!key) {
      key = EApiRequestPartKeys.FILTERS;
    }
    if (!partName) {
      partName = EBitfApiRequestPartsNames.FILTER;
    }
    super(key, partName, defaultPartData);
  }

  build(): void {
    const filterStringParts = [];
    if (this.formValue) {
      Object.entries(this.formValue)
        .filter(([_, value]) => {
          if (Array.isArray(value)) {
            return value.length;
          }
          return value !== null && value !== '' && value !== undefined;
        })
        .forEach(([filterProperty, value]: [string, any]) => {
          const formItemsConfig = this.data?.formItemsConfig[filterProperty];
          if (Array.isArray(value) && value.length) {
            // NOTE: operator is to combine multi select like with and or or condition
            const operator = formItemsConfig?.operator || EBitfGraphQlQueryOperator.OR;

            let arrayFilterString = `{"${operator}":[`;
            arrayFilterString += Array.from(value)
              .map(v => this.getFilterRule(filterProperty, v, formItemsConfig))
              .join(',');
            arrayFilterString += `]}`;

            filterStringParts.push(arrayFilterString);
          } else {
            const queryItem = this.getFilterRule(filterProperty, value, formItemsConfig);
            filterStringParts.push(queryItem);
          }
        });
    }

    const filterString = filterStringParts.join(',');
    this.part = filterString?.length && `${filterString}`;
  }

  private getFilterRule(filterProperty: string, value: string, formItemsConfig: BitfFormItem): string {
    value = this.getFormattedValue(value, formItemsConfig);
    const comparator = formItemsConfig.comparator || EBitfGraphQlQueryOperator.LIKE;
    const queryValue = comparator === EBitfGraphQlQueryOperator.LIKE ? `%${value}%` : value;

    if (comparator === EBitfGraphQlQueryOperator.EQUAL) {
      return `{"${this.mapName(filterProperty)}": "${queryValue}"}`;
    } else {
      return `{"${this.mapName(filterProperty)}": {"${comparator}" :"${queryValue}"}}`;
    }
  }

  private getFormattedValue(value: any, formItem: BitfFormItem): any {
    if (formItem?.metaData?.dateFormat === 'timestampSeconds') {
      return new Date(value).getTime() / 1000;
    }
    if (formItem?.metaData?.dateFormat === 'shortDate') {
      const newDate = new Date(value);
      return `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`;
    }
    return value;
  }

  private mapName(filterProperty: string) {
    const mapName = this.data?.formItemsConfig[filterProperty]?.mapName;
    return mapName || filterProperty;
  }
}
