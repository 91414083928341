import { CONSTANTS as COMMON_CONSTANTS } from '@common/constants/common.constants';
// eslint-disable-next-line max-len
import { BitfMatOkCancelDialogComponent } from '@common/libs/bitforce/core/components/ui/ok-cancel-dialog/material/bitf-mat-ok-cancel-dialog.component';
import { bitfToTranslate } from '@common/libs/bitforce/utils/bitf-translate.utils';

export const WEB_CONSTANTS = {
  fullScreenMessageComponent: null,
  okCancelDialogComponent: BitfMatOkCancelDialogComponent,
  VALIDATION_MESSAGES: {
    verificationCustomerCode: bitfToTranslate('PICKUP_IN_STORE.DIALOG_CONFIRM_PICKUP.INVALID_CODE_MESSAGE'),
  },
};

export const CONSTANTS = Object.assign(COMMON_CONSTANTS, WEB_CONSTANTS);
