import { Injectable, Injector } from '@angular/core';
import { PickupInStoreOrder } from '@common/core/models';
import { IBitfGraphQlRequest, IBitfGraphQlResponse } from '@common/interfaces';
import { BitfGraphQlService } from '@common/libs/bitforce/core/services/graph-ql/bitf-graph-ql.service';

import { gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { PICKUP_IN_STORE_DETAILS_FIELDS, PICKUP_IN_STORE_LIST_FIELDS } from './pickup-in-store.gql';
import { ORDER_FIELDS } from './order.gql';
import { PRODUCT_FIELDS } from './product.gql';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
// NOTE: Add methods following CRUD order
export class PickupInStoreService extends BitfGraphQlService {
  constructor(public injector: Injector) {
    super(injector);
  }

  patchPickupInStoreOrder(
    requestParams: IBitfGraphQlRequest<Partial<PickupInStoreOrder>> = {},
    order: Partial<PickupInStoreOrder>
  ) {
    if (typeof requestParams.id === 'string') {
      requestParams.id = parseInt(requestParams.id, 10);
    }
    requestParams.modelMapper = 'updateCollectOrdersInStore';
    requestParams.mutation = gql`
      mutation Mutate($id: Int!, $input: UpdateCollectOrdersInStoreInput!) {
        updateCollectOrdersInStore(id: $id, input: $input) {
          success
          output {
            status
          }
        }
      }
    `;
    return super.mutate<PickupInStoreOrder>(requestParams, order).pipe(
      tap((response: IBitfGraphQlResponse<PickupInStoreOrder>) => {
        Object.assign(order, response.content);
      })
    );
  }

  getPickupIsStoreOrders(
    requestParams: IBitfGraphQlRequest = {}
  ): Observable<IBitfGraphQlResponse<PickupInStoreOrder[]>> {
    requestParams.modelMapper = 'getCollectOrdersInStoreListing';
    requestParams.query = gql`
      ${PICKUP_IN_STORE_LIST_FIELDS}
      query Query($first: Int, $after: Int, $sortBy: [String], $sortOrder: [String], $filter: String) {
        getCollectOrdersInStoreListing(
          first: $first
          after: $after
          sortBy: $sortBy
          sortOrder: $sortOrder
          filter: $filter
        ) {
          edges {
            node {
              ...CollectOrdersInStoreFields
            }
          }
          totalCount
        }
      }
    `;
    return super.query<PickupInStoreOrder[]>(requestParams);
  }

  getById(requestParams: IBitfGraphQlRequest) {
    requestParams.modelMapper = 'getCollectOrdersInStore';
    requestParams.query = gql`
      ${PICKUP_IN_STORE_DETAILS_FIELDS}

      query Query($id: Int!) {
        getCollectOrdersInStore(id: $id) {
          ...CollectOrdersInStoreFields
        }
      }
    `;

    return super.query<PickupInStoreOrder>(requestParams);
  }

  count(requestParams: IBitfGraphQlRequest): Observable<number> {
    requestParams.modelMapper = 'getCollectOrdersInStoreListing';
    requestParams.query = gql`
      query Query($filter: String) {
        getCollectOrdersInStoreListing(filter: $filter) {
          totalCount
        }
      }
    `;
    return super
      .query<PickupInStoreOrder[]>(requestParams)
      .pipe(map(response => response.originalBody.getCollectOrdersInStoreListing.totalCount));
  }
}
