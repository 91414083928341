import { Injectable, Injector } from '@angular/core';
import { gql } from 'apollo-angular';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BitfGraphQlService } from '@bitf/services/graph-ql/bitf-graph-ql.service';

import { IBitfGraphQlRequest, IBitfGraphQlResponse } from '@interfaces';
import { ReturnOrder } from '@models';
import { RETURN_LIST_FIELDS, RETURN_FIELDS } from './return.gql';
import { ORDER_FIELDS } from './order.gql';
import { PRODUCT_FIELDS } from './product.gql';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
// NOTE: Add methods following CRUD order
export class ReturnsService extends BitfGraphQlService {
  constructor(public injector: Injector) {
    super(injector);
  }

  getReturns(requestParams: IBitfGraphQlRequest = {}): Observable<IBitfGraphQlResponse<ReturnOrder[]>> {
    requestParams.modelMapper = 'getReturnsListing';
    requestParams.query = gql`
      ${RETURN_LIST_FIELDS}
      query Query($first: Int, $after: Int, $sortBy: [String], $sortOrder: [String], $filter: String) {
        getReturnsListing(
          first: $first
          after: $after
          sortBy: $sortBy
          sortOrder: $sortOrder
          filter: $filter
        ) {
          edges {
            node {
              ...ReturnListFields
              items {
                metadata {
                  name
                  value
                }
              }
              order {
                ... on object_OnlineShopOrder {
                  id
                  origin
                  ordernumber
                  customerFirstname
                  customerLastname
                  order_customer_firstname
                  order_customer_lastname
                  items {
                    ... on object_OnlineShopOrderItem {
                      id
                    }
                  }
                }
              }
            }
          }
          totalCount
        }
      }
    `;

    return super.query<ReturnOrder[]>(requestParams);
  }

  getReturnById(requestParams: IBitfGraphQlRequest): Observable<IBitfGraphQlResponse<ReturnOrder>> {
    const {
      apiProperties: { size, objectProductSize },
    } = environment;

    requestParams.modelMapper = 'getReturns';
    requestParams.query = gql`
      ${RETURN_FIELDS}
      ${PRODUCT_FIELDS}
      query Query($id: Int!) {
        getReturns(id: $id) {
          ...ReturnFields
          items {
            element {
              ...ProductFields
              product {
                ... on object_Product {
                  sku
                  images {
                    ... on fieldcollection_ImageInfo {
                      image {
                        filename
                        fullpath
                      }
                    }
                  }
                  color {
                    ... on object_Color {
                      name
                    }
                  }
                  ${size} {
                    ... on ${objectProductSize} {
                      code
                    }
                  }
                  parent {
                    ... on object_Product {
                      sku
                      images {
                        ... on fieldcollection_ImageInfo {
                          image {
                            filename
                            fullpath
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            metadata {
              name
              value
            }
          }
          order {
            ... on object_OnlineShopOrder {
              currency
              origin
              pickingTipology
              customerFirstname
              customerLastname
              customerFullName
              customerPhone
            }
          }
        }
      }
    `;

    return super.query<ReturnOrder>(requestParams);
  }

  // NOTE we are using this call because it has less params than getReturns
  getReturnByOrderNumber(
    requestParams: IBitfGraphQlRequest
  ): Observable<IBitfGraphQlResponse<ReturnOrder[]>> {
    requestParams.modelMapper = 'getReturnsListing';
    requestParams.query = gql`
      query Query($filter: String!) {
        getReturnsListing(filter: $filter) {
          edges {
            node {
              requestTime
              status
            }
          }
        }
      }
    `;

    return super.query<ReturnOrder[]>(requestParams);
  }

  patchReturn(
    requestParams: IBitfGraphQlRequest<Partial<ReturnOrder>> = {},
    returnItem: Partial<ReturnOrder>
  ) {
    requestParams.body.id =
      typeof requestParams.body.id === 'string' ? parseInt(requestParams.body.id, 10) : requestParams.body.id;
    requestParams.id = requestParams.body.id;
    requestParams.modelMapper = 'updateReturns';
    requestParams.mutation = gql`
      mutation Mutate($id: Int!, $input: UpdateReturnsInput!) {
        updateReturns(id: $id, input: $input) {
          success
          message
          output {
            status
          }
        }
      }
    `;
    return super.mutate<ReturnOrder>(requestParams, returnItem).pipe(
      tap((response: IBitfGraphQlResponse<ReturnOrder>) => {
        Object.assign(returnItem, response.content);
      })
    );
  }

  count(requestParams: IBitfGraphQlRequest): Observable<number> {
    requestParams.modelMapper = 'getReturnsListing';
    requestParams.query = gql`
      query Query($filter: String) {
        getReturnsListing(filter: $filter) {
          totalCount
        }
      }
    `;
    return super
      .query<ReturnOrder[]>(requestParams)
      .pipe(map(response => response.originalBody.getReturnsListing.totalCount));
  }
}
