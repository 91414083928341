import { Injectable, Injector } from '@angular/core';
import { tap } from 'rxjs/operators';

import { gql } from 'apollo-angular';

import { BitfGraphQlService } from '@bitf/services/graph-ql/bitf-graph-ql.service';

import { IBitfGraphQlRequest, IBitfGraphQlResponse } from '@interfaces';
import { TicketItem } from '@models';
import { TICKET_ITEM_LIST_FIELDS } from '@services';

@Injectable({
  providedIn: 'root',
})
export class TicketItemsService extends BitfGraphQlService {
  constructor(public injector: Injector) {
    super(injector);
  }

  getTicketItemListing(requestParams: IBitfGraphQlRequest = {}) {
    requestParams.query = gql`
      ${TICKET_ITEM_LIST_FIELDS}
      query Query($first: Int, $after: Int, $filter: String) {
        getTicketItemListing(first: $first, after: $after, filter: $filter) {
          edges {
            node {
              ...TicketItemListFields
            }
          }
          totalCount
        }
      }
    `;
    requestParams.modelMapper = 'getTicketItemListing';
    return super.query<TicketItem[]>(requestParams);
  }

  updateTicketItem(
    requestParams: IBitfGraphQlRequest<Partial<TicketItem>> = {},
    ticketItem?: Partial<TicketItem>
  ) {
    requestParams.modelMapper = 'updateTicketItem';
    requestParams.mutation = gql`
      mutation Mutate($id: Int!, $input: UpdateTicketItemInput!) {
        updateTicketItem(id: $id, input: $input) {
          success
          message
          output {
            status
          }
        }
      }
    `;
    return super.mutate<TicketItem>(requestParams, ticketItem).pipe(
      tap((response: IBitfGraphQlResponse<TicketItem>) => {
        if (ticketItem) {
          Object.assign(ticketItem, response.content);
        }
      })
    );
  }
}
