import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { IDynamicTableConfig } from '@interfaces';

@Component({
  selector: 'soh-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicTableComponent implements OnInit {
  @Input() dataSource: any[];
  @Input() allColumns: IDynamicTableConfig[];
  @Input() maxWidth = 'none';
  @Input() tableClass = '';
  @Input() fullWidth = false;
  @Input() excludedColumns: string[] = [];
  @Input() showHeader: boolean = true;

  displayedColumns: IDynamicTableConfig[] = [];

  get columnIdentifiers(): string[] {
    return this.displayedColumns.map(column => column.property);
  }

  constructor() {}

  ngOnInit(): void {
    this.displayedColumns = this.allColumns.filter(column => !this.excludedColumns.includes(column.property));
  }
}
