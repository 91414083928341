import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class BitfMatPaginatorTranslations extends MatPaginatorIntl {
  ofLabel = '';
  constructor(private translateService: TranslateService) {
    super();

    this.itemsPerPageLabel = this.translateService.instant('BITF.MAT_PAGINATOR.ITEMS_PER_PAGE_LABEL');
    this.nextPageLabel = this.translateService.instant('BITF.MAT_PAGINATOR.NEXT_PAGE_LABEL');
    this.previousPageLabel = this.translateService.instant('BITF.MAT_PAGINATOR.PREVIOUS_PAGE_LABEL');
    this.firstPageLabel = this.translateService.instant('BITF.MAT_PAGINATOR.FIRST_PAGE_LABEL');
    this.lastPageLabel = this.translateService.instant('BITF.MAT_PAGINATOR.LAST_PAGE_LABEL');
    this.ofLabel = this.translateService.instant('BITF.LABEL.OF');
  }

  getRangeLabel = (page, pageSize, length) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.ofLabel} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    // startIndex + 1 + ' - ' + endIndex + ' od ' + length
    return `${startIndex + 1} - ${endIndex} ${this.ofLabel.toLowerCase()} ${length}`;
  };
}
