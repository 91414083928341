import { Injectable, Injector } from '@angular/core';
import { finalize, switchMap, tap } from 'rxjs/operators';

import { gql } from 'apollo-angular';

import { BitfGraphQlService } from '@bitf/services/graph-ql/bitf-graph-ql.service';

import { IBitfGraphQlRequest, IBitfGraphQlResponse } from '@interfaces';
import { Ticket, TicketItem } from '@models';
import {
  TicketItemsService,
  TICKET_FIELDS,
  TICKET_LIST_FIELDS,
  TICKET_PACKING_FIELDS,
  TICKET_PACKING_LIST_FIELDS,
} from '@services';
import { ETicketStatus } from '@common/enums';

@Injectable({
  providedIn: 'root',
})
export class TicketsService extends BitfGraphQlService {
  constructor(public injector: Injector, private ticketItemsService: TicketItemsService) {
    super(injector);
  }

  listOfTickets(requestParams: IBitfGraphQlRequest = {}) {
    requestParams.query = gql`
      ${TICKET_LIST_FIELDS}
      query Query($first: Int, $after: Int, $sortBy: [String], $sortOrder: [String], $filter: String) {
        getTicketListing(
          first: $first
          after: $after
          sortBy: $sortBy
          sortOrder: $sortOrder
          filter: $filter
        ) {
          edges {
            node {
              ...TicketListFields
            }
          }
          totalCount
        }
      }
    `;
    requestParams.modelMapper = 'getTicketListing';
    return super.query<Ticket[]>(requestParams);
  }

  getTicketById(requestParams: IBitfGraphQlRequest) {
    requestParams.modelMapper = 'getTicket';
    requestParams.query = gql`
      ${TICKET_FIELDS}
      query Query($id: Int!) {
        getTicket(id: $id) {
          ...TicketFields
        }
      }
    `;

    return super.query<Ticket>(requestParams);
  }

  getTicketPackingListing(requestParams: IBitfGraphQlRequest = {}) {
    requestParams.query = gql`
      ${TICKET_PACKING_LIST_FIELDS}
      query Query($first: Int, $after: Int, $sortBy: [String], $sortOrder: [String], $filter: String) {
        getTicketListing(
          first: $first
          after: $after
          sortBy: $sortBy
          sortOrder: $sortOrder
          filter: $filter
        ) {
          edges {
            node {
              ...TicketPackingListFields
            }
          }
          totalCount
        }
      }
    `;
    requestParams.modelMapper = 'getTicketListing';
    return super.query<Ticket[]>(requestParams);
  }

  getTicketByIdForPacking(requestParams: IBitfGraphQlRequest) {
    requestParams.modelMapper = 'getTicket';
    requestParams.query = gql`
      ${TICKET_PACKING_FIELDS}
      query Query($id: Int!) {
        getTicket(id: $id) {
          ...TicketPackingFields
        }
      }
    `;

    return super.query<Ticket>(requestParams);
  }

  updateTicket(requestParams: IBitfGraphQlRequest<Partial<Ticket>> = {}, ticket: Ticket) {
    requestParams.modelMapper = 'updateTicket';
    requestParams.mutation = gql`
      mutation Mutate($id: Int!, $input: UpdateTicketInput!) {
        updateTicket(id: $id, input: $input) {
          success
          message
          output {
            status
          }
        }
      }
    `;
    return super.mutate<Ticket>(requestParams, ticket).pipe(
      tap((response: IBitfGraphQlResponse<Ticket>) => {
        Object.assign(ticket, response.content);
      })
    );
  }

  // Actions

  onPackReady(ticket: Ticket, ticketStatus: ETicketStatus) {
    this.loaderService.show();
    const arrayOfMutations = ticket.ticketItems.map((ticketItem: TicketItem) =>
      // FIXME: Status
      this.ticketItemsService.updateTicketItem({
        id: ticketItem.id,
        body: {
          status: ticketItem.status,
        },
        disableHideLoader: true,
      })
    );

    return this.getThrottledObservables(arrayOfMutations).pipe(
      finalize(() => this.loaderService.hide()),
      switchMap(_ =>
        this.updateTicket(
          {
            id: ticket.id,
            body: {
              status: ticketStatus,
            },
          },
          ticket
        )
      )
    );
  }
}
