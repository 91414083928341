import { Component, Input } from '@angular/core';
import { EDataLoadingState } from '@enums';

@Component({
  selector: 'soh-list-state-manager',
  templateUrl: './list-state-manager.component.html',
  styleUrls: ['./list-state-manager.component.scss'],
})
export class ListStateManagerComponent {
  @Input() emptyListMessage: string;
  @Input() notFoundMessage: string;
  @Input() dataLoadingState: EDataLoadingState;

  public eDataLoadingState = EDataLoadingState;

  constructor() {}
}
