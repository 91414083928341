import { Injectable } from '@angular/core';
import { BitfDynamicLocaleService } from '@common/libs/bitforce/core/services/locale/bitf-dynamic-locale.service';

@Injectable({
  providedIn: 'root',
})
export class BitfTextToSpeechService {
  private isSpeaking = false;
  public isSupported: boolean;
  configurations: { selectedVoice?: SpeechSynthesisVoice; rate: number; pitch: number } = {
    rate: 1,
    pitch: 1,
  };
  allVoices: SpeechSynthesisVoice[];

  constructor(private bitfDynamicLocaleService: BitfDynamicLocaleService) {}

  init(configurations = {}) {
    Object.assign(this.configurations, configurations);
    return new Promise<void>((success, error) => {
      if ('speechSynthesis' in window === false) {
        this.isSupported = false;
        error('text to speech not supported');
        return;
      }
      this.isSupported = true;

      // NOTE: we've to wait for the bitfDynamicLocaleService init, this is called in the app session service
      setTimeout(() => {
        speechSynthesis.onvoiceschanged = () => {
          this.loadDefatultVoice();
          success();
        };

        // NOTE: fallback for iOS because onvoiceschanged is not fired
        setTimeout(() => {
          if (!this.configurations.selectedVoice) {
            this.loadDefatultVoice();
            success();
          }
        }, 1000);
      }, 1000);
    });
  }

  speech(text: string = '', terminatePreviuosSpeech = true): Promise<any> {
    if (!this.isSupported) {
      return Promise.reject();
    }
    return new Promise<void>((success, error) => {
      if (!this.isSupported) {
        error('text to speach not supported');
      }
      try {
        if (this.isSpeaking && terminatePreviuosSpeech) {
          speechSynthesis.cancel();
        }
        this.isSpeaking = true;

        const msg = new SpeechSynthesisUtterance();
        msg.voice = this.configurations.selectedVoice;
        msg.rate = this.configurations.rate;
        msg.pitch = this.configurations.pitch;
        msg.text = text;
        msg.onend = () => {
          this.isSpeaking = false;
          success();
        };
        speechSynthesis.speak(msg);
      } catch (e) {
        error(e);
      }
    });
  }

  multipleSpeach(texts, delayBetweenPhrases = 500): Promise<any> {
    if (!this.isSupported) {
      return Promise.reject();
    }
    const p = Promise.resolve();
    texts.reduce(
      (acc, text) =>
        acc.then(
          () =>
            new Promise(success => {
              setTimeout(() => {
                success(this.speech(text));
              }, delayBetweenPhrases);
            })
        ),
      p
    );
    return p;
  }

  stopSpeech() {
    if (!this.isSupported) {
      return;
    }
    speechSynthesis.cancel();
    this.isSpeaking = false;
  }

  setLanguage(language: string) {
    if (!this.isSupported) {
      return;
    }
    if (language === 'en') {
      language = 'en-GB';
    }
    const voices = this.allVoices.filter(voice => voice.lang.includes(language));
    if (voices.length) {
      this.configurations.selectedVoice = voices[0];
    }
  }

  syncVoiceLanguageLocaleWithLocale() {
    this.bitfDynamicLocaleService.localeChange$.subscribe(newLocale => {
      this.setLanguage(newLocale);
    });
  }

  private loadDefatultVoice() {
    if (!this.isSupported) {
      return;
    }
    this.allVoices = speechSynthesis.getVoices();
    try {
      let browserLocale = this.bitfDynamicLocaleService.locale.code;
      if (browserLocale === 'en') {
        browserLocale = 'en-GB';
      }
      const localizedVoices = this.allVoices.filter(voice => voice.lang.includes(browserLocale));
      const defaultLocalizedVoice = localizedVoices.find(voice => voice.default);
      this.configurations.selectedVoice = defaultLocalizedVoice || localizedVoices[0];
    } catch (e) {
      if (this.allVoices.length) {
        this.configurations.selectedVoice = this.allVoices[0];
      }
    }
  }
}
