import { Injectable } from '@angular/core';
import { EBitfAuthState } from '@common/enums';
import { ApiCallStateService, AuthService } from '@common/services';
import { EApiCallStateNames, EApiRequestPartKeys } from '../api-call-state/api-call-state.enum';
import { WarehouseFilterApiRequestPart } from '../api-call-state/warehouse-filter.api-request-part';

@Injectable({
  providedIn: 'root',
})
export class WarehousesFilterService {
  constructor(private authService: AuthService, private apiCallStateService: ApiCallStateService) {}

  init() {
    this.updateApiCallStatesIfTokenValid();
    this.initListener();
  }

  private initListener() {
    this.authService.authState$.subscribe(authState => {
      if (authState === EBitfAuthState.TOKEN_RETRIEVED) {
        this.updateApiCallStatesIfTokenValid();
      }
    });
  }

  private updateApiCallStatesIfTokenValid() {
    if (!this.authService.isTokenValid) {
      return;
    }

    const warehousesAllowed = this.getWarehousesList();
    const warehouseFilterRequestsParts = this.getWarehouseFilterRequestParts();

    if (!warehouseFilterRequestsParts?.length) {
      return;
    }

    warehouseFilterRequestsParts.forEach(requestPart => {
      requestPart.updateWarehouses(warehousesAllowed);
    });

    const updatedApiCallStatesNames = this.apiCallStateService.getApiCallStatesNamesContainingRequestPart(
      EApiRequestPartKeys.WAREHOUSE_FILTER
    );

    updatedApiCallStatesNames.forEach(apiCallStateName => {
      this.apiCallStateService.setStore(() => {}, apiCallStateName);
    });
  }

  private getWarehouseFilterRequestParts(): WarehouseFilterApiRequestPart[] {
    return this.apiCallStateService.getAllRequestPartsByKey<WarehouseFilterApiRequestPart>(
      EApiRequestPartKeys.WAREHOUSE_FILTER
    );
  }

  private getWarehousesList(): number[] {
    return this.authService.authTokenMetaData?.['inventory']?.['warehouses'];
  }
}
