import { SuperModel } from './super-model.model';

export class User extends SuperModel {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  constructor(data = {}) {
    super(data);
  }
  get serialised() {
    return {};
  }

  get canViewLogs() {
    return true;
  }
}
