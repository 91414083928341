const baseApiUrl = 'https://acmilan.sintra-soh.com';
const apiUrl = `${baseApiUrl}/api/`;
const graphQlUrl = `${baseApiUrl}/pimcore-graphql-webservices/webapp`;

export const environment = {
  production: false,
  name: 'acmilan-production',
  appName: 'SOH - App Retail for Milan',
  storageVersion: '1.0.0',
  baseApiUrl,
  apiUrl,
  graphQlUrl,
  authInterceptorUrls: [apiUrl, graphQlUrl],
  showClickCollectCode: true,
  mockApiUrl: 'https://localhost:3002/',
  logUrl: '',
  sockets: { url: '' },
  httpRetryConfig: {
    enabled: true,
    verbs: {
      get: { enabled: true, timeout: 6000, maxRetries: 2, retryForStatusesCodes: [500] },
      post: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      patch: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      put: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      delete: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
    },
  },
  graphQlConfig: {
    enabled: true,
    requestTimeout: 10000,
  },
  loggerConfig: {
    enabled: true,
    maxLogRentention: 20,
    logHttpErrorsWithStatusesCodes: ['0', '4XX', '5XX'],
    logDecoratedExceptions: true,
    logExceptions: true,
    saveLogMessagesInterval: 30000,
    logUrl: '',
    sendLogs: false,
    sendRequestBody: true,
    sendQueryParams: true,
  },
  tagManager: {
    enabled: false,
    apiKey: '',
  },
  registerServiceWorker: true,
  httpPassword: 'SOHub4Demo',
  mainLogoUrl: '',
  customerLogoUrl: '/assets/logo/ac_milan_logo.png',
  apiProperties: {
    size: 'size_id',
    objectProductSize: 'object_ProductSize',
  },
  returns: {
    enableReturnedStatusButton: false,
  },
};
