import { Component, Input, OnChanges } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { CONSTANTS } from '@common/constants/common.constants';
import { IStatusOption } from '@interfaces';
import { EStatusColor } from '@enums';

@Component({
  selector: 'soh-status-viewer',
  templateUrl: './status-viewer.component.html',
  styleUrls: ['./status-viewer.component.scss'],
})
export class StatusViewerComponent implements OnChanges {
  @Input() label: string;
  @Input() color: EStatusColor;
  @Input() icon: string;
  @Input() isClickable = true;

  status: IStatusOption;

  protected emptyStatus = {
    label: this.translateService.instant('COMMON.LABEL.STATUS_NOT_DEFINED'),
    color: CONSTANTS.COLORS[EStatusColor.GREY],
  };

  constructor(private translateService: TranslateService) {}

  ngOnChanges(): void {
    this.status = this.label
      ? {
          label: this.label,
          color: {
            background: CONSTANTS.COLORS[this.color].background,
            text: CONSTANTS.COLORS[this.color].text,
          },
          icon: this.icon,
        }
      : this.emptyStatus;
  }
}
