import { Injectable, Injector } from '@angular/core';
import { BitfApiService } from '@bitf/services/api/bitf-api.service';
import { EBitfInterceptors } from '@common/enums';
import { IBitfApiRequest } from '@interfaces';
import { map } from 'rxjs/operators';

interface IPimcoreJwtLogin {
  token: string;
}

@Injectable({
  providedIn: 'root',
})
export class PimcoreJwtService extends BitfApiService {
  constructor(public injector: Injector) {
    super('login_check', injector);
  }

  getToken(body: { username: string; password: string }) {
    return this.request<IPimcoreJwtLogin>({
      method: 'POST',
      path: '',
      body,
      isBodyRaw: true,
      headers: [{ headerName: EBitfInterceptors.BITF_API_ERRORS_INTERCEPTOR, value: 'OK' }],
    } as IBitfApiRequest).pipe(
      // NOTE: this is a patch because the api is not respecting the envelope
      map(response => {
        response.content = response.originalBody;
        return response;
      })
    );
  }
}
