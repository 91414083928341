import { User } from '@models';
import justClone from 'just-clone';

export abstract class SuperModel {
  id?: string | number;
  createdAt?: string | Date;
  modifiedAt?: string | Date;
  createdBy: string | User;
  modifiedBy: string | User;

  constructor(object: any = {}) {
    Object.assign(this, object);
    if (!object) {
      return;
    }
    if (typeof object.createdBy === 'object') {
      this.createdBy = new User(object.createdBy);
    }
    if (typeof object.modifiedBy === 'object') {
      this.modifiedBy = new User(object.modifiedBy);
    }
  }

  abstract readonly serialised;

  get clone() {
    return justClone(this.serialised);
  }

  serialiseDate(date: Date) {
    if (!date || !(date instanceof Date)) {
      return '';
    }

    const monthNum = date.getMonth() + 1;
    const month = monthNum < 10 ? `0${monthNum}` : monthNum;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    return `${date.getFullYear()}-${month}-${day}`;
  }
}
