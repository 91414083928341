<div [class.row]="!fullWidth">
  <table mat-table
    [dataSource]="dataSource"
    [style]="{maxWidth}"
    [class]="tableClass">

    <ng-container *ngFor="let column of displayedColumns"
      matColumnDef="{{column.property}}">

      <ng-container *ngIf="showHeader">
        <!-- Header -->
        <th mat-header-cell
            *matHeaderCellDef
            class="{{column.classHeader}}">
          {{column.title}}
        </th>
      </ng-container>

      <!-- Cell -->
      <td mat-cell
        *matCellDef="let item"
        class="{{column.classRow}}">

        <ng-container *ngIf="column.template"
          [ngTemplateOutlet]="column.template"
          [ngTemplateOutletContext]="{item:item}"></ng-container>

        <span *ngIf="!column.template">
          {{item[column.property]}}
        </span>
      </td>

    </ng-container>

    <ng-container *ngIf="showHeader">
      <tr mat-header-row *matHeaderRowDef="columnIdentifiers"></tr>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: columnIdentifiers;"></tr>

  </table>
</div>
