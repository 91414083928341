import { SuperModel } from './super-model.model';

export class Test extends SuperModel {
  public name: string;

  constructor(data = {}) {
    super(data);
  }

  get serialised() {
    return {
      name: this.name,
    };
  }
}
