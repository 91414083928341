import { ECurrency } from '@common/enums';
import { SuperModel } from './super-model.model';
import { Product } from './product.model';

export class OrderItem extends SuperModel {
  amount: number;
  productName: string;
  totalPrice: number;
  currency: string;
  product?: Product;

  constructor(data: any = {}) {
    super(data);

    if (!data.currency) {
      this.currency = ECurrency.EUR;
    }

    if (data && data.product) {
      this.product = new Product({ element: data.product });
    }
  }
  get serialised() {
    return {};
  }
}
