import { Component, Input, OnInit } from '@angular/core';
import { ISimpleListItem } from './simple-list.interface';

@Component({
  selector: 'soh-simple-list',
  templateUrl: './simple-list.component.html',
  styleUrls: ['./simple-list.component.scss'],
})
export class SimpleListComponent implements OnInit {
  @Input() items: ISimpleListItem[];
  @Input() title: string;
  @Input() className = '';

  constructor() {}

  ngOnInit(): void {}
}
