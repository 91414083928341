<!-- NOTE: uncomment if we implement skeleton list -->
<!-- <div *ngIf="dataLoadingState === eDataLoadingState.LOADING">
    Loading...
  </div> -->

<mat-card class="border mx-auto"
  *ngIf="dataLoadingState === eDataLoadingState.LIST_EMPTY || dataLoadingState === eDataLoadingState.LIST_ITEMS_NOT_FOUND">
  <div class="d-flex align-items-center">
    <mat-icon color="primary">info</mat-icon>
    <h2 class="pl-2 m-0">
      {{(dataLoadingState === eDataLoadingState.LIST_EMPTY) ?emptyListMessage :notFoundMessage}}
    </h2>
  </div>
</mat-card>

<div class="state-manager-content">
  <ng-content *ngIf="dataLoadingState === eDataLoadingState.LIST_NOT_EMPTY"></ng-content>
</div>