import { gql } from 'apollo-angular';

export const TICKET_LIST_FIELDS = gql`
  fragment TicketListFields on object_Ticket {
    id
    title
    status
    creationDate
    customerFullName
    OrderNumber
    code
    ticketItems: children {
      ... on object_TicketItem {
        id
      }
    }
    order {
      ... on object_OnlineShopOrder {
        id
      }
    }
  }
`;

export const TICKET_PACKING_LIST_FIELDS = gql`
  fragment TicketPackingListFields on object_Ticket {
    id
    title
    status
    creationDate
    customerFullName
    OrderNumber
    code
    ticketItems: children {
      ... on object_TicketItem {
        id
        code
        status
        creationDate
        currentShop {
          ... on object_Warehouse {
            name
          }
        }
        product {
          metadata {
            name
            value
          }
          element {
            ... on object_Product {
              id
              images {
                ... on fieldcollection_ImageInfo {
                  image {
                    fullpath(thumbnail: "wswwebapp")
                    filename
                  }
                }
              }
              name
              modello
              size
              colorCode
              sku
            }
          }
        }
      }
    }
    order {
      ... on object_OnlineShopOrder {
        id
      }
    }
  }
`;

export const TICKET_FIELDS = gql`
  fragment TicketFields on object_Ticket {
    id
    title
    status
    creationDate
    customerFullName
    OrderNumber
    code
    order {
      ... on object_OnlineShopOrder {
        id
      }
    }
  }
`;

export const TICKET_PACKING_FIELDS = gql`
  fragment TicketPackingFields on object_Ticket {
    id
    title
    status
    creationDate
    customerFullName
    OrderNumber
    code
    order {
      ... on object_OnlineShopOrder {
        id
      }
    }
    ticketItems: children {
      ... on object_TicketItem {
        id
        code
        status
        creationDate
        currentShop {
          ... on object_Warehouse {
            name
          }
        }
        product {
          metadata {
            name
            value
          }
          element {
            ... on object_Product {
              id
              images {
                ... on fieldcollection_ImageInfo {
                  image {
                    fullpath(thumbnail: "wswwebapp")
                    filename
                  }
                }
              }
              name
              modello
              size
              colorCode
              sku
            }
          }
        }
      }
    }
  }
`;
