import { Injectable, Injector } from '@angular/core';
import { gql } from 'apollo-angular';

import { BitfGraphQlService } from '@bitf/services/graph-ql/bitf-graph-ql.service';
import { IBitfGraphQlRequest, IBitfGraphQlResponse } from '@interfaces';
import { Order } from '@models';
import { ORDER_LIST_FIELDS, ORDER_FIELDS } from './order.gql';
import { PRODUCT_FIELDS } from './product.gql';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
// NOTE: Add methods following CRUD order
export class OrdersService extends BitfGraphQlService {
  constructor(public injector: Injector) {
    super(injector);
  }

  createOrder(requestParams: IBitfGraphQlRequest = {}) {
    requestParams.modelMapper = 'createTestWebapp';
    requestParams.mutation = gql`
      mutation Mutate($key: String!, $parentId: Int!, $input: UpdateTestWebappInput!) {
        createTestWebapp(key: $key, parentId: $parentId, input: $input) {
          success
          message
          output {
            id
            name
          }
        }
      }
    `;

    return super.query<Order>(requestParams);
  }

  getOrders(requestParams: IBitfGraphQlRequest = {}): Observable<IBitfGraphQlResponse<Order[]>> {
    requestParams.modelMapper = 'getOnlineShopOrderListing';
    requestParams.query = gql`
      ${ORDER_LIST_FIELDS}
      query Query($first: Int, $after: Int, $sortBy: [String], $sortOrder: [String], $filter: String) {
        getOnlineShopOrderListing(
          first: $first
          after: $after
          sortBy: $sortBy
          sortOrder: $sortOrder
          filter: $filter
        ) {
          edges {
            node {
              ...OrderListFields
            }
          }
          totalCount
        }
      }
    `;
    return super.query<Order[]>(requestParams);
  }

  getOrderById(requestParams: IBitfGraphQlRequest) {
    requestParams.modelMapper = 'getOnlineShopOrder';
    requestParams.query = gql`
      ${ORDER_FIELDS}
      ${PRODUCT_FIELDS}
      query Query($id: Int!) {
        getOnlineShopOrder(id: $id) {
          ...OrderFields
          items {
            ...ProductFields
          }
        }
      }
    `;

    return super.query<Order>(requestParams);
  }

  patchOrder(requestParams: IBitfGraphQlRequest<Partial<Order>> = {}, order: Partial<Order>) {
    requestParams.modelMapper = 'updateOnlineShopOrder';
    requestParams.mutation = gql`
      mutation Mutate($id: Int!, $input: UpdateOnlineShopOrderInput!) {
        updateOnlineShopOrder(id: $id, input: $input) {
          success
          output {
            orderState
          }
        }
      }
    `;
    return super.mutate<Order>(requestParams, order).pipe(
      tap((response: IBitfGraphQlResponse<Order>) => {
        Object.assign(order, response.content);
      })
    );
  }

  deleteOrder(requestParams: IBitfGraphQlRequest = {}) {
    requestParams.modelMapper = 'deleteTestWebapp';
    requestParams.mutation = gql`
      mutation Mutate($id: Int!) {
        deleteTestWebapp(id: $id) {
          success
          message
        }
      }
    `;
    return super.mutate<Order>(requestParams);
  }
}
