import { IBitfApiRequest, IBitfApiMockDbRequestMapped } from '@interfaces';
import { BitfRestRequestMapper } from '@bitf/parsers/rest-parser/bitf-rest-request.mapper';

import { EBitfParsers } from '@enums';

export class BitfMockDbRequestMapper extends BitfRestRequestMapper {
  name = EBitfParsers.BITF_MOCK_DB_PARSER;

  constructor() {
    super();
  }

  map(requestParams: IBitfApiRequest): IBitfApiMockDbRequestMapped {
    const { query, sorting, page, size, embed, search } = requestParams;
    const requestMapped = super.map(requestParams);
    const params: any = {};

    if (query) {
      if (Array.isArray(query)) {
        query.forEach(subQuery => {
          // NOTE: this is considering to have subQuery in a form of p-parser style
          Object.assign(params, { [`${subQuery.name}_like`]: subQuery.value });
        });
      } else {
        Object.assign(params, query);
      }
    }
    if (sorting && sorting.length) {
      params._sort = sorting[0].property;
      params._order = sorting[0].direction;
    }

    if (page !== undefined) {
      params._page = page;
    }

    if (size !== undefined) {
      params._limit = size;
    }

    if (embed) {
      params._embed = embed.join(',');
    }

    if (search) {
      if (typeof search === 'string') {
        params.q = search;
      } else {
        const firstValue = search[Object.keys(search)[0]];
        if (firstValue !== undefined) {
          params.q = firstValue;
        }
      }
    }

    requestMapped.params = requestMapped.params || {};

    if (Object.keys(params).length) {
      Object.assign(requestMapped.params, params);
    }

    return requestMapped as IBitfApiMockDbRequestMapped;
  }
}
