import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'soh-material-icon-provider',
  templateUrl: './material-icon-provider.component.html',
  styleUrls: ['./material-icon-provider.component.scss'],
})
export class MaterialIconProviderComponent {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'dashboard-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/dashboard_icon.svg')
    );
    iconRegistry.addSvgIcon(
      'return-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/returns_icon.svg')
    );
    iconRegistry.addSvgIcon(
      'click-and-collect-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/click_collect_icon.svg')
    );
  }
}
