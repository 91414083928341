import { NativeDateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { Optional, Inject, Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { formatDate } from '@angular/common';

import { BitfDynamicLocaleService } from '@bitf/services/locale/bitf-dynamic-locale.service';

@Injectable()
export class BitfDateAdapterService extends NativeDateAdapter {
  defaultFormat = 'yyyy-MM-dd';
  localeFormatMap: { [key: string]: string } = {
    it: 'dd/MM/yyyy',
  };
  firstDayMap: { [key: string]: number } = {
    it: 1,
    en: 7,
  };
  defaultFirstDay = 1;

  constructor(
    @Optional()
    @Inject(MAT_DATE_LOCALE)
    matDateLocale: string,
    platform: Platform,
    protected bitfDynamicLocaleService: BitfDynamicLocaleService
  ) {
    super(matDateLocale, platform);
    this.bitfDynamicLocaleService.localeChange$.subscribe(locale => {
      if (locale) {
        this.setLocale(locale);
      }
    });
  }

  format(date: Date): string {
    return formatDate(date, this.getFormat(), this.locale);
  }

  parse(value: string): Date | null {
    const format = this.getFormat();
    if (value.length !== format.length) {
      return null;
    }

    const ddIndex = format.indexOf('dd');
    const mMIndex = format.indexOf('MM');
    const yyyyIndex = format.indexOf('yyyy');

    const day = parseFloat(value.substr(ddIndex, 2));
    const month = parseFloat(value.substr(mMIndex, 2)) - 1;
    const year = parseFloat(value.substr(yyyyIndex, 4));

    const date = new Date();
    date.setDate(day);
    date.setMonth(month);
    date.setFullYear(year);

    return date;
  }

  private getFormat() {
    return this.localeFormatMap[this.locale] || this.localeFormatMap[this.lang] || this.defaultFormat;
  }

  getFirstDayOfWeek() {
    return this.firstDayMap[this.lang] ? this.firstDayMap[this.lang] : this.defaultFirstDay;
  }

  private get lang() {
    return this.locale.substr(0, 2);
  }
}
