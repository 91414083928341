import { SuperModel } from './super-model.model';
import { Product } from '@models';
import { ETicketItemStatus } from '@enums';

export class TicketItem extends SuperModel {
  CurrentShopName: string;
  TicketCode: string;
  code: string;
  ddtAlpha: string;
  requestId: number;
  shippingNumber: string;
  status: ETicketItemStatus;
  product: Product;
  creationDate: number;
  currentShop: { name: string };
  missingProducts: number; // FE only for picking and packing lists

  constructor(data: Partial<TicketItem> = {}) {
    super(data);

    if ((data.product as any)?.length > 0) {
      const product = data.product[0] as any;
      this.product = new Product(product);
    } else if (data.product) {
      this.product = new Product(data.product as any);
    }
  }

  get serialised() {
    return {};
  }
}
