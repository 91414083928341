import { gql } from 'apollo-angular';

export const TICKET_ITEM_LIST_FIELDS = gql`
  fragment TicketItemListFields on object_TicketItem {
    id
    code
    status
    creationDate
    currentShop {
      ... on object_Warehouse {
        name
      }
    }
    product {
      metadata {
        name
        value
      }
      element {
        ... on object_Product {
          id
          images {
            ... on fieldcollection_ImageInfo {
              image {
                fullpath(thumbnail: "wswwebapp")
                filename
              }
            }
          }
          name
          modello
          size
          colorCode
          sku
        }
      }
    }
  }
`;
