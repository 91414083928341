<mat-toolbar class="white-toolbar small pl-1 pr-1">
  <button *ngIf="showBackButton"
    mat-icon-button
    color="primary"
    (click)="back.emit()">
    <mat-icon>arrow_back</mat-icon>
  </button>

  <h1 class="m-0"
    [ngClass]="{'pl-3':!showBackButton}">
    {{title | translate}}
  </h1>

  <ng-content select="[toolbar-left]"></ng-content>

  <span class="flex-grow-1"></span>

  <div class="d-flex align-items-center">

    <div *ngIf="!storeService.store.activeBreakpoints.isHandset  && showHeaderFilters && formItemsConfig">

      <bitf-form-item-container [apiCallStateName]="apiCallStateName"
        [apiRequestPartKey]="filtersApiRequestPartKey"
        [form]="form"
        (submitForm)="filter.emit()">
        <div class="d-flex align-items-center">
          <bitf-mat-form-item *ngIf="formItemsConfig.ordernumber"
            [formItem]="formItemsConfig.ordernumber"
            formItemControlName="ordernumber"
            [form]="form"
            matFormFieldClass="mat-form-field-in-toolbar w-100"></bitf-mat-form-item>

          <bitf-mat-form-item *ngIf="formItemsConfig.customerFullName"
            [formItem]="formItemsConfig.customerFullName"
            formItemControlName="customerFullName"
            [form]="form"
            matFormFieldClass="mat-form-field-in-toolbar ml-2 w-100"></bitf-mat-form-item>

          <!-- <bitf-mat-form-item *ngIf="formItemsConfig.customerEmail"
        [formItem]="formItemsConfig.customerEmail"
        formItemControlName="customerEmail"
        [form]="form"
        matFormFieldClass="mat-form-field-in-toolbar ml-2"></bitf-mat-form-item>

      <bitf-mat-form-item *ngIf="formItemsConfig.email"
        [formItem]="formItemsConfig.email"
        formItemControlName="email"
        [form]="form"
        matFormFieldClass="mat-form-field-in-toolbar ml-2"></bitf-mat-form-item> -->

          <button mat-flat-button
            color="accent"
            class="d-none d-sm-block mx-3">
            <span>{{'COMMON.LABEL.APPLY' | translate}}</span>
          </button>

        </div>

      </bitf-form-item-container>

    </div>

    <ng-content select="[toolbar-right]"></ng-content>

    <button mat-button
      color="accent"
      *ngIf="showSidebarFiltersButton"
      (click)="openFilters.emit()">
      <mat-icon>filter_list</mat-icon>
      <span class="ml-2">{{'COMMON.LABEL.FILTERS' | translate}}</span>
    </button>

    <a *ngIf="!storeService.store.activeBreakpoints.isHandset  && showHeaderFilters && formItemsConfig"
      class="d-none d-sm-block ml-2 mr-2 cursor-pointer text-sm-center soh-reset-filter-link text-gray-light"
      (click)="resetFilter()">{{'COMMON.LABEL.RESET' | translate}}</a>
  </div>
</mat-toolbar>