import {
  BitfMockDbPathBuilder,
  BitfMockDbRequestMapper,
  BitfMockDbResponseMapper,
  BitfMockDbEnvelopeMapper,
} from '@bitf/core/parsers/mock-db-parser';

import {
  BitfPimcoreRequestMapper,
  BitfPimcoreEnvelopeMapper,
  BitfPimcoreResponseMapper,
} from '@common/libs/bitforce/core/parsers/pimcore-parser';
import { AppEnvelopeMapper, AppPathBuilder, AppRequestMapper, AppResponseMapper } from '@web/core/parsers';

import { IBitfParserStrategy, IBitfGraphQlParserStrategy } from '@interfaces';

export const BITF_CONFIGS: any = {
  toastMessage: { durationMs: 5000 },
  apiErrorsInterceptorService: { interceptHttpResponsesWithCode: ['0', '401', '4XX', '5XX'] },
  parsers: {
    defaultParser: 'appParser',
    defaultGraphQlParser: 'pimcoreParser',
    parserStrategies: {
      appParser: {
        pathBuilder: new AppPathBuilder(),
        requestMapper: new AppRequestMapper(),
        responseMapper: new AppResponseMapper(),
        envelopeMapper: new AppEnvelopeMapper(),
      } as IBitfParserStrategy,
      mockDbParser: {
        pathBuilder: new BitfMockDbPathBuilder(),
        requestMapper: new BitfMockDbRequestMapper(),
        responseMapper: new BitfMockDbResponseMapper(),
        envelopeMapper: new BitfMockDbEnvelopeMapper(),
      } as IBitfParserStrategy,
      pimcoreParser: {
        requestMapper: new BitfPimcoreRequestMapper(),
        envelopeMapper: new BitfPimcoreEnvelopeMapper(),
        responseMapper: new BitfPimcoreResponseMapper(),
      } as IBitfGraphQlParserStrategy,
    },
  },
};
