import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'soh-table-toolbar',
  templateUrl: './table-toolbar.component.html',
  styleUrls: ['./table-toolbar.component.scss'],
})
export class TableToolbarComponent {
  @Input() selectedRows: SelectionModel<any>;
  @Input() countLabel: string;
  @Input() areAllRowsSelected: boolean;
  @Output() toggleAllRows = new EventEmitter();

  constructor() {}
}
