export class BitfOption {
  id: string | number;
  label: string;
  isDefault: boolean;
  metaData: any;
  options?: BitfOption[];

  constructor(data: any = {}) {
    Object.assign(this, data);
    if (data.options) {
      this.options = data.options.map(item => new BitfOption(item));
    }
  }

  get serialised() {
    return this.id;
  }

  toString() {
    return this.label;
  }
}
