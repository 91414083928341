import { ETicketStatus } from '@enums';
import { Order, TicketItem } from '@models';
import { SuperModel } from './super-model.model';

export class Ticket extends SuperModel {
  title: string;
  status: ETicketStatus;
  creationDate: string;
  customerFullName: string;
  OrderNumber: string;
  code: string;
  order: Order;
  // destinationShop: Warehouse;
  ticketItems: TicketItem[];

  constructor(data: Partial<Ticket> = {}) {
    super(data);

    if (data.order) {
      this.order = new Order(data.order);
    }

    // if (data.destinationShop) {
    //   this.destinationShop = new Warehouse(data.destinationShop);
    // }

    if (data.ticketItems) {
      this.ticketItems = data.ticketItems.map(ticketItem => new TicketItem(ticketItem));
    }
  }

  get serialised() {
    return {};
  }
}
