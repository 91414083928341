import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';
import { EBitfApiRequestPartsNames, EApiRequestPartKeys, EBitfApiSortDirection } from '@enums';
import { IBitfPartData } from '@interfaces';

export class BitfSortApiRequestPart extends BitfApiRequestPart {
  constructor({
    key,
    partName,
    defaultPartData,
  }: {
    key?: EApiRequestPartKeys;
    partName?: EBitfApiRequestPartsNames;
    defaultPartData?: IBitfPartData;
  } = {}) {
    if (!key) {
      key = EApiRequestPartKeys.SORTING;
    }
    if (!partName) {
      partName = EBitfApiRequestPartsNames.SORTING;
    }
    super(key, partName, defaultPartData);
  }

  build(): void {
    if (this.data && this.data.direction) {
      this.part = this.data;
    } else {
      this.part = undefined;
    }
  }

  setUndefinedDirection() {
    this.data.direction = EBitfApiSortDirection.UNDEFINED;
  }
}
