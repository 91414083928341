import { Injectable, Injector } from '@angular/core';

import { BitfTouchEvent } from '@common/libs/bitforce/core/models';
// eslint-disable-next-line max-len
import { BitfTouchEventsService } from '@common/libs/bitforce/directives/bitf-touch-events/bitf-touch-events.service';

import { ETouchEvent, EBitfTouchEventType, ETouchEventTriggerId } from '@web/enums';

@Injectable({
  providedIn: 'root',
})
export class TouchEventsService extends BitfTouchEventsService {
  constructor(public injector: Injector) {
    super();

    this.events = [
      new BitfTouchEvent({
        id: ETouchEvent.DEMO_1,
        milliseconds: 2000,
        triggers: [ETouchEventTriggerId.DEMO_TOUCH_EVENT_BTN],
        eventType: EBitfTouchEventType.DOWN,
        sound: 'assets/sounds/switch.mp3',
      }),
    ];
  }
}
