import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app.module';
import { environment } from '@env/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(module => {
    window['rootInjector'] = module.injector;
  })
  .catch(err => console.log(err));

if ('serviceWorker' in navigator && environment.registerServiceWorker) {
  navigator.serviceWorker.register('/ngsw-worker.js').catch(e => {
    console.log('error registering service worker', e);
  });

  // NOTE this is an attempt to update the DW on PWA iOs Safari
  // navigator.serviceWorker.getRegistrations().then(function(registrations) {
  //   for (const registration of registrations) {
  //     registration.update();
  //   }
  // });
}
