import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'soh-main-logo',
  templateUrl: './main-logo.component.html',
  styleUrls: ['./main-logo.component.scss'],
})
export class MainLogoComponent implements OnInit {
  logo = new URL('./logo.jpg', import.meta.url);

  constructor() {}

  ngOnInit(): void {}
}
