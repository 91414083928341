import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BitfFormItemConfig } from '@common/libs/bitforce/core/models';

import { EApiCallStateNames, EApiRequestPartKeys } from '@web/enums';
import { ApiCallStateService, StoreService } from '@services';

@Component({
  selector: 'soh-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() showHeaderFilters = true;
  @Input() showSidebarFiltersButton = true;
  @Input() showBackButton = false;
  @Input() apiCallStateName: EApiCallStateNames;
  @Input() filtersApiRequestPartKey: EApiRequestPartKeys;
  @Input() formItemsConfig: BitfFormItemConfig;
  @Input() form: UntypedFormGroup;

  @Output() openFilters: EventEmitter<void> = new EventEmitter();
  @Output() filter: EventEmitter<void> = new EventEmitter();
  @Output() back: EventEmitter<void> = new EventEmitter();

  constructor(public storeService: StoreService, private apiCallStateService: ApiCallStateService) {}

  ngOnInit(): void {}

  resetFilter() {
    this.form.reset();
    const requestPart = this.apiCallStateService.getRequestPart(
      this.apiCallStateName,
      this.filtersApiRequestPartKey
    );
    requestPart.reset();
    this.apiCallStateService.setStore(() => {}, this.apiCallStateName);
    this.filter.emit();
  }
}
