import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { bitfToTranslate } from '@common/libs/bitforce/utils/bitf-translate.utils';

@Component({
  selector: 'bitf-mat-date-range-input',
  templateUrl: './mat-date-range-input.component.html',
  styleUrls: ['./mat-date-range-input.component.scss'],
})
export class BitfMatDateRangeInputComponent implements OnInit {
  @Input() title: string = bitfToTranslate('COMMON.LABEL.SELECT_PERIOD');
  @Input() dateRangeForm: UntypedFormGroup;
  @Input() hourOfTheDayMs: number;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() matFormFieldClass: string;

  constructor() {}

  ngOnInit(): void {}

  onStartDateChange(event) {
    if (this.hourOfTheDayMs && event?.value) {
      const date: Date = event.value;
      date.setHours(0, 0, 0, 0);
      this.dateRangeForm.patchValue(
        { startDate: new Date(date.getTime() + this.hourOfTheDayMs) },
        {
          emitEvent: false,
        }
      );
    }
  }

  onEndDateChange(event) {
    if (this.hourOfTheDayMs && event?.value) {
      const date: Date = event.value;
      date.setHours(0, 0, 0, 0);
      this.dateRangeForm.patchValue(
        { endDate: new Date(date.getTime() + this.hourOfTheDayMs) },
        {
          emitEvent: false,
        }
      );
    }
  }
}
