export enum ERoleMode {
  READ_ONLY = 'READ_ONLY',
  HIDDEN = 'HIDDEN',
  DISABLED = 'DISABLED',
  INVISIBLE = 'INVISIBLE',
}

export enum ERoles {
  CLERK = 'CLERK',
  STOREKEEPER = 'STOREKEEPER',
  SHOP_MANAGER = 'SHOP_MANAGER',
}

export enum ERoleActions {
  VIEW_LOG = 'VIEW_LOG',
}
