import * as Models from '@models';

export const modelsMap = new Map<string, any>([
  ['getTestWebappListing', Models.Test],
  ['createTestWebapp', Models.Test],
  ['deleteTestWebapp', Models.Test],
  ['users', Models.User],
  ['getOnlineShopOrder', Models.Order],
  ['getOnlineShopOrderListing', Models.Order],
  ['updateOnlineShopOrder', Models.Order],
  ['getReturnsListing', Models.ReturnOrder],
  ['updateReturns', Models.ReturnOrder],
  ['getReturns', Models.ReturnOrder],
  ['getTicket', Models.Ticket],
  ['getTicketListing', Models.Ticket],
  ['updateTicket', Models.Ticket],
  ['getTicketItemListing', Models.TicketItem],
  ['updateTicketItem', Models.TicketItem],
  ['getCollectOrdersInStoreListing', Models.PickupInStoreOrder],
  ['getCollectOrdersInStore', Models.PickupInStoreOrder],
  ['updateCollectOrdersInStore', Models.PickupInStoreOrder],
]);
