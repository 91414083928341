/* eslint-disable max-len */
import { Component, Input, OnInit } from '@angular/core';
import { IBitfLanguage } from '@common/interfaces';

import { BitfDynamicLocaleService } from '@common/libs/bitforce/core/services/locale/bitf-dynamic-locale.service';

import { CONSTANTS } from '@constants';
/* eslint-enable max-len */

@Component({
  selector: 'bitf-language-selector',
  template: '',
})
export class BitfLanguageSelectorComponent implements OnInit {
  @Input() useShortLabel = false;

  supportedLanguages = CONSTANTS.SUPPORTED_LANGUAGES;

  constructor(public bitfDynamicLocaleService: BitfDynamicLocaleService) {}

  ngOnInit(): void {}

  changeLocale(language: IBitfLanguage) {
    this.bitfDynamicLocaleService.setLocale(language);
  }
}
