import { BitfFormItem, BitfFormItemConfig } from '@bitf/core/models';
import { BitGraphQlFormItemConfigApiRequestPart } from '@common/libs/bitforce/core/api-call-state/bitf-graph-ql-api-call-state';
import { EBitfApiRequestPartsNames, EApiRequestPartKeys, EBitfGraphQlQueryOperator } from '@enums';

export class WarehouseFilterApiRequestPart extends BitGraphQlFormItemConfigApiRequestPart {
  constructor(public storePropertyName: string) {
    super({
      key: EApiRequestPartKeys.WAREHOUSE_FILTER,
      partName: EBitfApiRequestPartsNames.FILTER,
      defaultPartData: {
        data: {
          formItemsConfig: new BitfFormItemConfig({
            [storePropertyName]: new BitfFormItem({
              label: '',
              type: 'multipleSelect',
              comparator: EBitfGraphQlQueryOperator.EQUAL,
            }),
          }),
        },
      },
    });
  }

  updateWarehouses(warehouses: number[]) {
    this.formValue = {
      [this.storePropertyName]: warehouses,
    };
    this.data.formValue = {
      [this.storePropertyName]: warehouses,
    };
    this.build();
  }
}
