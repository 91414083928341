import { Injectable, Injector } from '@angular/core';
import { BitfJwtAuthService } from '@bitf/services/auth/jwt/bitf-jwt-auth.service';
import { EBitfAuthState } from '@common/enums';
import { IBitfApiResponse } from '@common/interfaces';
import { PimcoreJwtService } from '@services';
import { BITF_CONFIGS } from '@web/configs';
import { User } from '@web/core/models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BitfJwtAuthService {
  constructor(protected injector: Injector, private pimcoreJwtService: PimcoreJwtService) {
    super(injector);
  }

  listenForAuthStateChange(): Observable<EBitfAuthState> {
    return this.authState$.pipe(
      tap((authState: EBitfAuthState) => {
        if (authState === EBitfAuthState.TOKEN_RETRIEVED && this.authTokenMetaData) {
          this.usersService.getMe().subscribe(
            (response: IBitfApiResponse<User>) => {
              this.setUser(response.content);
              this.onLoginSuccess();
              this.handleRedirect();
            },
            () => {
              this.onLoginError();
            }
          );
        }
      })
    );
  }

  handleAuthentication(accessData) {
    this.authState$.next(EBitfAuthState.LOGIN_IN_PROGRESS);
    this.pimcoreJwtService.getToken(accessData).subscribe(
      response => {
        this.signIn({ encodedToken: response.content.token });
      },
      () => {
        this.onLoginError();
      }
    );
  }

  onLoginError(errorMessage = '', authState: EBitfAuthState = EBitfAuthState.LOGIN_ERROR) {
    this.authState$.next(authState);
    this.clearAuthData();

    // NOTE this is to prevent the user refresh the page in login error state with an invalid token
    // this will reload the page without queryParams (the token) so the page will redirect again to the
    // oauth service login page
    if (location.search) {
      this.router.navigateByUrl(BITF_CONFIGS.urls.signInUrl);
    }
  }
}
