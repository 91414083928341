<div>
  <h3 *ngIf="title">{{title | translate}}</h3>
  <mat-form-field appearance="outline"
    [ngClass]="matFormFieldClass">
    <mat-label>{{'COMMON.LABEL.DATE_RANGE' | translate}}</mat-label>
    <mat-date-range-input [formGroup]="dateRangeForm"
      [rangePicker]="picker"
      [min]="minDate"
      [max]="maxDate">
      <input matStartDate
        (dateChange)="onStartDateChange($event)"
        formControlName="startDate"
        placeholder="{{'COMMON.LABEL.START_DATE' | translate}}">
      <input matEndDate
        (dateChange)="onEndDateChange($event)"
        formControlName="endDate"
        placeholder="{{'COMMON.LABEL.END_DATE' | translate}}">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix
      [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>

    <mat-error *ngIf="dateRangeForm.controls.startDate.hasError('matStartDateInvalid')">
      {{'COMMON.LABEL.INVALID_START_DATE' | translate}}
    </mat-error>
    <mat-error *ngIf="dateRangeForm.controls.endDate.hasError('matEndDateInvalid')">
      {{'COMMON.LABEL.INVALID_END_DATE' | translate}}
    </mat-error>
  </mat-form-field>
</div>