import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class JwtRequestInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.endsWith('/api/user/data')) {
      const dupReq = req.clone({
        headers: req.headers.set('Cache-Control', 'no-cache'),
      });

      return next.handle(dupReq);
    }

    return next.handle(req);
  }
}
