import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

import { BitfDynamicLocaleService } from '../core/services/locale/bitf-dynamic-locale.service';

import { CONSTANTS } from '@common/constants/common.constants';

@Pipe({ name: 'bitfCurrency', pure: false })
export class BitfCurrencyPipe implements PipeTransform {
  private lastLocale: string;
  private lastResult: string;
  private lastValue: any;
  private currencyPipe: CurrencyPipe;

  constructor(private bitfDynamiclocaleService: BitfDynamicLocaleService) {
    this.currencyPipe = new CurrencyPipe(
      this.bitfDynamiclocaleService.locale.code,
      CONSTANTS.DEFAULT_CURRENCY.code
    );
  }

  transform(
    value: any,
    currencyCode?: string,
    display?: string,
    digitsInfo?: string,
    locale?: string
  ): string {
    try {
      locale = locale || this.bitfDynamiclocaleService.locale.code;
      display = display || 'symbol-narrow';

      if (value !== this.lastValue || locale !== this.lastLocale) {
        this.lastLocale = locale;
        this.lastResult = this.currencyPipe.transform(value, currencyCode, display, digitsInfo, locale);
        this.lastValue = value;
      }

      return this.lastResult;
    } catch (error) {
      console.error(error);
      return value;
    }
  }
}
