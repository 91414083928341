import { gql } from 'apollo-angular';

export const PRODUCT_FIELDS = gql`
  fragment ProductFields on object_OnlineShopOrderItem {
    id
    amount
    productName
    totalPrice
  }
`;
