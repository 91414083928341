import { environment } from '@env/environment';
import { SuperModel } from './super-model.model';

export class Asset extends SuperModel {
  fullpath: string;
  filename: string;

  constructor(data: Partial<Asset> = {}) {
    super(data);
  }

  get serialised() {
    return {};
  }

  get url() {
    return this.fullpath ? environment.baseApiUrl.concat(this.fullpath) : '';
  }
}
