import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'soh-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  mainLogoUrl: string;
  customerLogoUrl: string;

  constructor() {}

  ngOnInit(): void {
    const { mainLogoUrl, customerLogoUrl } = environment;
    this.mainLogoUrl = mainLogoUrl;
    this.customerLogoUrl = customerLogoUrl;
  }
}
