<div *ngIf="selectedRows.hasValue()"
  class="actions-bar w-100 d-flex align-items-center justify-content-between py-2">

  <div>
    <mat-checkbox (change)="$event ? toggleAllRows.emit() : null"
      [checked]="selectedRows.hasValue() && areAllRowsSelected"
      [indeterminate]="selectedRows.hasValue() && !areAllRowsSelected">
    </mat-checkbox>

    <span class="pl-2">
      {{selectedRows.selected.length}} <span class="d-none d-sm-inline">{{ countLabel | translate}}</span>
    </span>
  </div>

  <ng-content></ng-content>
</div>